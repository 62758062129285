<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-03-29 16:49:50
 * @LastEditTime: 2021-05-24 16:55:08
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="rule">
    <LogoTitle msg="基金会章程"></LogoTitle>
    <div class="rule-title">思利及人基金会章程</div>
    <RichArticle :html="html"></RichArticle>
  </div>
</template>

<script>
export default {
  data () {
    return {
      html: ''
    }
  },
  created () {
    this.getDetail();
  },
  methods: {
    getDetail () {
      let params = {
        categoryId: '1376790851161853953'
      }
      this.$api.getCategoryDetail(params).then(res => {
        if (res.data.data) {
          this.html = res.data.data.contentDetails;
        }
      })
    }
  },
}
</script>

<style scoped lang='scss'>
.rule-title {
  font-size: 18px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #666666;
  line-height: 32px;
  border-bottom: 1px solid #ebedf0;
  text-align: center;
  &::after {
    content: "";
    display: block;
    width: 40px;
    height: 3px;
    background: $main-color;
    margin: 0 auto;
  }
}
</style>